<template>
  <div>
    <div class="header_conner">
      <el-header class="header-top">
        <div>
          <el-row>
            <el-col :span="5">
              <h1>
                <router-link  to="/">
                  <img alt="安全，万方安全,信息安全，网络安全，安全服务，信息安全设备，网络信息安全，信息安全服务，等级保护，风险评估，代码审计，安全运维" class="logo" src="@/assets/images/logo.png">
                </router-link>
              </h1>
            </el-col>
            <el-col :span="10">
              <!--          </el-row>-->
              <!--          <el-row>-->
              <ul class="header_bottom" id="mymenu">
                <li  v-for="(menu,key) in menus" v-show="menu.menParentId==0" :key=key  >
                  <router-link v-show="menu.menChildtableId===0" :to="menu.menUrl" @mouseenter="sideshow=false;enter(menu.menTable,key);" @mouseleave="sideshow=false;">{{menu.menName}}</router-link>
                  <!--<div v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId" class="desc_box">
                    <router-link :to="childmenu.menUrl">{{childmenu.menName}}</router-link>
                  </div>-->
                  <a v-show="menu.menParentId===0&&menu.menChildtableId!=0" href="javascript:void(0)" @mouseenter="sideshow=true;enter(menu.menTable,key);" @mouseleave="sideshow=false;">{{menu.menName}}<i class="el-icon-arrow-down"></i></a>
                  <!--<div v-show="desc" class="desc_box">
                    <router-link v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId"  :to="childmenu.menUrl">{{childmenu.menName}}</router-link>
                  </div> @mouseleave="leave(menu.menTable,key);"-->
<!--                  <div v-show="sideshow" class="sides"><Side/></div>-->
<!--                  <div v-show="sideshow" class="sides"> -->
<!--                  <div v-show="sideshow" class="sides">-->
                    <template v-if="show===1&&menu.menTable==='m_product_type'">
                    <ul>
<!--                      <li v-show="show===1&&menu.menTable==='m_product_type'" v-for="(ProductType,key) in m_product_type" :key=key >-->
<!--                        <a  href="https://www.eet-china.com/webinars" target="_blank">{{ProductType.proTypeName}}</a>-->
<!--                        <a v-show="menu.menParentId===0&&menu.menChildtableId!=0" href="javascript:" @mouseenter="enter(menu.menTable,key);" @mouseleave="leave(menu.menTable,key);">{{ProductType.proTypeName}}<i class="el-icon-arrow-down"></i></a> -->
<!--                      </li>-->



                      <li v-show="show===1&&menu.menTable==='m_product_type'" v-for="(ProductType,key) in m_product_type" :key=key>
<!--                        <div>&lt;!&ndash;<div class="side_tit" > class="side_tit"class="nen_tits":to="childmenu.menUrl"&ndash;&gt;-->
                           <a v-show="ProductType.proTypeParentId===0" href="javascript:void(0)" @mouseenter="productshow=true;Proenter(ProductType.proTypeId,key)" >{{ProductType.proTypeName}}</a>

<!--                     <li v-show="ProductType.proTypeParentId===0" @mouseenter="productshow=true;"productshow=false; ;@mouseleave="productshow=false;"productshow=true;>-->
<!--                          <router-link v-show="ProductType.proTypeParentId===0"  @mouseenter="productshow=true;" @mouseleave="productshow=false;">@mouseleave="Proenter(ProductType.proTypeId,index);"-->
<!--                            {{ProductType.proTypeName}}-->
<!--                          </router-link>-->
<!--                    </li>-->
                          <!--        <p v-show="abc">-->
                          <!--          <router-link v-for="(childmenu,key) in childmenus" :key=key target="_blank" :to="menu.menUrl">{{ childmenu.menName }}</router-link>-->
                          <!--          <el-divider direction="vertical"></el-divider>-->
                          <!--          <router-link target="_blank"  to="details">等保三级</router-link>-->
                          <!--          <el-divider direction="vertical"></el-divider>-->
                          <!--          <router-link target="_blank" to="/dengbaomeal">等保套餐</router-link>-->
                          <!--        </p>-->
<!--                        </div>&&showPro===41v-for="(ProductType1,index) in m_product_type"-->
<!--                        <div class="menu_box"productshow :class="{ active3: 0 != current1 }">&ndash;&gt;v-for="(ProductType1,index) in m_product_type" -->

                          <div  v-show="productshow" class="menu_box">
<!--                            <h4>等保整改{{key}}</h4>-->
                            <ul>
<!--                              <li>{{ ProductType.proTypeName}}</li>-->
                              <li v-for="(ProductType1,key) in m_product_type" v-show="productshow&&ProductType1.proTypeParentId===showPro" :key="key" @mouseleave="productshow=false;"><router-link target="_blank" to="/dengbaomeal">{{ProductType1.proTypeName}}</router-link></li>
                            </ul>
                          </div>
<!--&lt;!&ndash;                          <div class="menu_box_right">&ndash;&gt;-->

<!--&lt;!&ndash;                          </div>&ndash;&gt;-->

<!--                        </div>-->
                      </li>















                    </ul>
                    </template>

<!--                  </div>-->
<!--                  <div v-else-if="sideshow&&menu.menTable==='m_solu_type'">-->



<template v-else-if="sideshow&&show===2&&menu.menTable==='m_solu_type'">
                     <ul>
                       <li v-show="show===2&&menu.menTable==='m_solu_type'" v-for="(SoluType,key) in m_solu_type" :key=key ><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.soluTypeName}}</a></li>
                     </ul>
</template>

                  <template v-else-if="sideshow&&show===3&&menu.menTable==='m_info_type'">
                    <ul>
                      <li v-show="show===3&&menu.menTable==='m_info_type'" v-for="(SoluType,key) in m_info_type" :key=key ><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.infoTypeName}}</a></li>
                    </ul>
                  </template>
                  <template v-else-if="sideshow&&show===4&&menu.menTable==='m_hot_type'">
                    <ul>
                      <li v-show="show===4&&menu.menTable==='m_hot_type'" v-for="(SoluType,key) in m_hot_type" :key=key ><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.hotTypeName}}</a></li>
                    </ul>
                  </template>
                  <template v-else-if="sideshow&&show===5&&menu.menTable==='m_ecosphere'">
                    <ul>
                      <li v-show="show===5&&menu.menTable==='m_ecosphere'" v-for="(SoluType,key) in m_ecosphere" :key=key ><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.ecoName}}</a></li>
                    </ul>
                  </template>
<!--                  <template v-else-if="show===6&&menu.menTable==='m_wf_type'">-->
<!--                    <ul>-->
<!--                      <li v-show="show===6&&menu.menTable==='m_wf_type'" v-for="(SoluType,key) in m_wf_type" :key=key ><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.wfTypeName}}</a></li>-->
<!--                    </ul>-->
<!--                  </template>-->

                  <div  v-show="sideshow&&show===6&&menu.menTable==='m_wf_type'">
                    <ul>
                      <li v-show="show===6&&menu.menTable==='m_wf_type'" v-for="(SoluType,key) in m_wf_type" :key=key >
                        <a class="thismenu" href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.wfTypeName}}</a>
                      </li>
                    </ul>
                  </div>

<!--                  <template v-else-if="show===7&&menu.menTable===''">-->
<!--                    <ul>-->
<!--                      <li><a  href="https://www.eet-china.com/webinars" target="_blank">{{SoluType.soluTypeName}}</a></li>-->
<!--                    </ul>-->
<!--                  </template>-->


<!--                  </div>-->
<!--                  <div v-else>-->
<!--                    C-->
<!--                  </div>-->

<!--                                             <div v-show="sideshow"  class="dropdown-menu dropdown-nav">-->
<!--                                               <ul>-->
<!--                                               <li><a href="https://www.eet-china.com/webinars" target="_blank">在线研讨会</a></li>-->
<!--                                               <li><a href="https://www.eet-china.com/video" target="_blank">视频</a></li>-->
<!--                                               <li><a href="https://www.eet-china.com/downloads" target="_blank">白皮书</a></li>-->
<!--                                               <li><a href="https://www.eet-china.com/iqs" target="_blank">小测验</a></li>-->
<!--                                               <li><a href="https://www.eet-china.com/rc" target="_blank">资源中心</a></li>-->
<!--                                               </ul>-->
<!--                                             </div>-->
<!--                  </div>-->
                </li>
              </ul>
            </el-col>
            <el-col :span="3">
                <el-input
                    prefix-icon="el-icon-search"

                    placeholder="请输入搜索内容"
                    class="search-input"
                >
                    <el-button slot="append" type="primary">搜索</el-button>
                  </el-input>
            </el-col>
            <el-col :span="10">
              <div class="menuList">
                <router-link class="el-icon-shopping-cart-full"  to="/">购物车</router-link>
                <router-link  to="/">控制台</router-link>
                <router-link  to="/login">登录</router-link>
                <el-button class="register" type="primary">
                  <router-link  style="color:#fff;margin-right:0;" to="/register">免费注册</router-link>
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-header>

    </div>
<!--    <router-view></router-view>-->
<!--    <el-backtop></el-backtop>-->
<!--    <Footer/>-->
    <div class="sidebar-rolling">
      <ul>
        <li class="hidden-xs">
          <a target="_blank" href="https://mbb.eet-china.com/home/me-1850786.html">
                <span class="iconfont">
                    
                </span>
            <strong>联系客服</strong>
          </a>
        </li>
        <li class="back-top">
          <a href="javascript:void(0)">
                <span class="iconfont">
                    
                </span>
            <strong class="hidden-xs">返回顶部</strong>
          </a>
        </li>
        <li class="shopping-cart">
          <a href="https://estore.eet-china.com/cart/index">
                <span class="iconfont">
                    
                    <span class="cart-num">1</span>
                </span>
            <strong class="hidden-xs">购物车</strong>
          </a>
        </li>
      </ul>
    </div>


  </div>



</template>

<script type="text/javascript">
// import Footer from "@/components/Footer.vue"
// import Side from "@/components/Side.vue"
/*import * as HttpUtil from "@/utils/request";
import * as apiMap from "@/constants/api";
import {Message} from "element-ui";*/

//import { Vue } from "./vue";

//Vue.prototype.$axios = axios


export default{
  // name: "Header20210612",

  components:{
    // Footer,
    // Side
  },
  el:"#mymenu",
  data(){
    return{
      current1:100,
      searchInfo: "",
      activeName: "全部",
      sidevi:true,
      sideshow:true,
      sideshow1:[],
      show:999,
      showPro:999,
      sideshow_m_solu_type:false,
      sideshow_m_product_type:false,
      sideshow_m_info_type:false,
      sideshow_m_hot_type:false,
      sideshow_m_ecosphere:false,
      sideshow_m_wf_type:false,
      tablename:[],
      desc:false,
      productshow:false,
      menus:[],
      // childmenus:[],
      // childofmenus:[],
      searchList: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "等保"
        },
        {
          id: 2,
          name: "风评"
        },
        {
          id: 3,
          name: "安服"
        }
      ],
      ProductTypesAll:[],
      SoluTypesAll:[],
      InfoTypesAll:[],
      HotTypesAll:[],
      EcospheresAll:[],
      WfTypesAll:[],
      m_product_type:[],
      m_solu_type:[],
      m_info_type:[],
      m_hot_type:[],
      m_ecosphere:[],
      m_wf_type:[],
      DesTypes:[],
    }
  },
  mounted(){
    if(this.$route.path=='/'){
      this.sidevi=false
    }else{
      this.sidevi=true
    }

  },
  methods:{
    enter(abc,bcd) {
      //   for(let i=0;i<8;i++)
      //   {
      //     this.sideshow1[i] = false;
      //   }
      //   this.sideshow1[bcd] = true;
      //
      this.tablename[bcd+1]="(ProductType,key) in "+abc;
      //
      this.show=bcd+1;
      this.sideshow_m_solu_type=false;
      this.sideshow_m_product_type=false;
      this.sideshow_m_info_type=false;
      this.sideshow_m_hot_type=false;
      this.sideshow_m_ecosphere=false;
      this.sideshow_m_wf_type=false;
      // this.sideshow=true;

      // this.$message({
      //   message: this.show,
      //   type: 'success',
      // });
    },
    leave(abc,bcd) {
      this.show=999;
    },
    Proenter(abc,bcd) {
      this.showPro=abc;//bcd+1;
    },
    Proleave(abc,bcd) {
      this.showPro=999;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    mouseenter(num) {
      this.current1 = num;
    },
    mouseleave() {
      this.current1 = 1000;
    },

    page(currentPage){
      // return new Promise((resolve,reject)=>{
      //   HttpUtil.get(apiMap.GET_MENU_LIST,requestData,{isLoading:false}).then((res)=>{
      //     if(res.error==0){
      //       resolve(res);
      //       // Message({
      //       //      	type:'success',
      //       //      	message:res.content
      //       //      });
      //     }else{
      //       Message({
      //         type:'error',
      //         message:res.content
      //       });
      //     }
      //   })
      // });
      const _this = this
      // _this.$axios.get("/menus?currentPage=" + currentPage).then(res => {
      let modelid="2";
      _this.$axios.get("/menusWithModelId?modelid=" + modelid).then(res => {
        var data = res.data.data
        _this.menus = data
      })
      _this.$axios.get("/ProductTypesAll").then(res => {
        var data = res.data.data
        _this.m_product_type = data
      })
      _this.$axios.get("/SoluTypesAll").then(res => {
        var data = res.data.data
        _this.m_solu_type = data
        // _this.$message({
        //   message: _this.ProductTypesAll,
        //   type: 'success',
        // });
      })
      _this.$axios.get("/InfoTypesAll").then(res => {
        var data = res.data.data
        _this.m_info_type = data
      })
      _this.$axios.get("/HotTypesAll").then(res => {
        var data = res.data.data
        _this.m_hot_type = data
      })
      _this.$axios.get("/EcospheresAll").then(res => {
        var data = res.data.data
        _this.m_ecosphere = data
      })
      _this.$axios.get("/WfTypesAll").then(res => {
        var data = res.data.data
        _this.m_wf_type = data
      })
    },
  },
  watch:{
    $route(to,from){
      if(to.path=='/'){
        this.sidevi=false
      }else{
        this.sidevi=true
      }
    }
  },
  created() {
    this.page(1)
  }

};
</script>


<style lang="less"  scoped >
.header_conner{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  background:#fff;

  .header-top{
    width:1700px;
    min-width: 1700px;
    margin:0 auto;
    margin-bottom:0;
    height: auto !important;
  }
  .el-header{
    height: auto;
  }
  .logo{
    width:200px;
    margin-left:120px;
    margin-top:3px;
  }
  .el-header{
    padding: 0;
    background-color: #0e82cb;
  }
  .el-header {
    background-color: white;
    padding-top:20px;
  }
  .el-tabs__item.is-active {
    background-color: #0e82cb;
    color: white;
    border-bottom:1px solid #0e82cb;
  }
  .el-tabs__item.is-active:hover {
    color: white;
  }
  .el-tabs__item:hover {
    color: black;
  }
  .el-tabs__item:nth-child(2){
    padding-left:0;
  }
  .el-tabs__item{
    font-size:12px;
    width: auto;
    padding: 0 8px;
    line-height: 24px;
    text-align:center;
    height:24px;
    border-left:0;
  }
  .el-button--primary {
    color: #FFF;
    background-color: #0e82cb;
    border-color: #0e82cb;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #0e82cb;
    border-color: #0e82cb;
    color: #FFF;
  }
  .el-input-group__append button.el-button{
    color:#FFF;
    background-color: #0e82cb;
    border-color: #0e82cb;
  }
  .register{
    padding:4px 20px;
  }
  .menuList {
    margin-top: 20px;
    float: right;
    display: flex;
    //align-items: center;
    margin-left:850px;
    position: absolute;


    //margin: auto;
    //float: left;
  }
  .menuList a {
    margin-right: 15px;
    font-size:14px;
    text-decoration: none;
    color: black;
  }
  .menuList a:hover {
    color:#0e82cb;
  }
  .el-tabs__header {
    margin: 0px;
  }
  .el-tabs--card>.el-tabs__header {
    border:0;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border:0;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    border-bottom: 1px solid #0e82cb;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-left:0;
    height: 28px;
    line-height: 28px;
  }
  .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 10px;
  }
  /deep/.el-input__inner{
    height:32px;
    line-height: 32px;
    border:2px solid #0e82cb;
  }
  .el-input__inner:hover,.el-input__inner:focus{
    border:2px solid #0e82cb;
  }
  .mt5 a{
    font-size:12px;
    display:inline-block;
    text-decoration: none;
    color:#999;
    margin-top:3px;
  }
  .mt5 a:hover{
    color:#0e82cb;
  }
  //.header_bottom{
  //  padding:0;
  //  margin-top:20px;
  //  margin-bottom:15px;
  //  display: flex;
  //}
  .header_bottom {
    position:absolute;
    //top:20;
    //left:40;
    //right:0;
    display: flex;
    margin:0 auto;
    float: left;
    /*text-align:center*/
  }
.header-handle {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-align:center;
    align-items:baseline;
  }
  .header_bottom>li{
    /*position: relative;*/
  }
  .header_bottom a{
    padding: 0 16px;
    color: #181818;
    font-size: 14px;
    line-height: 35px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    overflow: hidden;
  }
  .header_bottom a:hover{
    color:#0e82cb;
  }
  .el-input__icon{
    line-height: 33px
  }
  .phones{
    float:right;
    position: absolute;
    right:10px;
    bottom:10px;
    font-size:16px;
    line-height: 30px;
  }
  .phones img{
    margin-right:5px;
    margin-left:10px;
  }
  .sides{
    padding-top:5px;
    position: absolute;
    top:28px;
    left:0px;
    z-index:8;
    width:240px;
  }
  .pane_items /deep/.el-tabs__header{
    margin:0;
  }
  .pane_items /deep/.el-tabs__item{
    height:30px;
    line-height: 30px;
  }
  .desc_box{
    position:absolute;
    background:#fff;
    padding-top:15px;
    z-index:5;
  }
  .desc_box a{
    width:65px;
    text-align:center;
    font-size:14px;
    display: block;
    color:#181818;
    border-top:1px solid #ddd;
  }
.search-input {
  //flex:1;
  height:35px;
  width:220px;
  //margin:50px 0 2px 15px;
  //padding:0;
  //color:#606266;
  //border:none;
  //outline:0;
  box-sizing:border-box;
  //margin-outside: 300;
  margin-left:800px;
  //margin-right: 400px;
  margin-top: 15px;


  //position:absolute;
  //top:20;
  //left:40;
  //right:0;
  //margin:0 auto;
  //float: right;
  /*text-align:center*/
}

  .nen_tits{
    font-size:14px;
    color:#181818;
    display: inline-block;
    line-height: 35px;
  }
  .menu_box{
    position: absolute;
    //left:100%;
    top:30px;
    overflow: hidden;
    width:800px;
    min-height:300px;
    background:#fff;
    padding: 30px 20px 0;
    box-sizing: border-box;
    z-index: 16;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    margin-left:200px;
  }
  .menu_box h4{
    padding:5px;
    color:#181818;
    font-size:14px;
    margin-bottom:10px;
    font-weight:600;
  }
  .menu_box ul{
    padding:5px;
    overflow:hidden;
  }
  .menu_box ul li{
    width:50%;
    float:left;
    padding-bottom:5px;
  }
  .menu_box ul li a{
    display: inline-block;
    color: #999;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 27px;
    vertical-align: middle;
  }
  .menu_box ul li a:hover{
    color:#0e82cb;
  }
  .menu_box_left{
    width: 500px;
    margin-right: 15px;
    float: left;
  }
  .menu_box_right{
    width:140px;
    float: right;
  }
  .menu_box_right dt{
    width: 206px;
    height: 130px;
    border: 1px solid #ededed;
    display: inline-block;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  .menu_box_right dt:hover{
    border:1px solid #0e82cb;
  }
  .menu_box_right dt a{
    width:100%;
    height:100%;
    display: inline-block;
    position: relative;
  }
  .menu_box_right dt a p{
    font-size: 14px;
    color: #999;
    display: block;
  }
  .menu_box_right dt a span{
    font-size: 14px;
    color: #0e82cb;
    line-height: 35px;
    cursor: pointer;
    text-decoration: none;
  }
  .menu_box_right dt a img{
    width: 64px;
    height: 64px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .active3{
    display: none;
  }
  .thismenu {
    //padding: 0 16px;
    //color: #181818;
    //font-size: 14px;
    //line-height: 35px;
    cursor: pointer;
    //display: inline-block;
    //text-decoration: none;
    //overflow: hidden;
  }
  .thismenu a:hover{
    color:#0e82cb;
  }
}
.sidebar-rolling{
  position: fixed;
  bottom: 100px;
  right: 0;
  z-index: 999;
}
.sidebar-rolling li{
  width: 80px;
  height: 90px;
  background: #fff;
  border: 1px solid #ededed;
  text-align: center;
  border-top: none;
  position: relative;
}
.sidebar-rolling li a{
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-rolling li span,.sidebar-rolling li strong{
  display: block;
}
.sidebar-rolling li:first-child{
  border-top: 1px solid #ededed;
}
.sidebar-rolling li .iconfont{
  font-size: 24px;
  color: #b2b2b2;
  position: relative;
}


</style>
