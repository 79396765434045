<template>
  <div class="footers">
    <div class="link_list">
<!--      <router-link to="/introduction">关于我们</router-link>-->
<!--      <router-link to="#">最新资讯</router-link>-->
<!--      <router-link to="#">帮助中心</router-link>-->
<!--      <router-link to="#">网站导航</router-link>-->
      <!-- <router-link to="/introduction">联系我们</router-link> -->
            <a v-for="(link,key) in links" @click="getUrl(link.linUrl)" :key="key">{{ link.linName }}</a>
<!--      <a class="a-style" @click="linkDownload('https://www.baidu.com')">百度</a>-->
    </div>
    <p class="tit">广州万方安全技术有限公司 &copy;版权所有 2008-2020
      <a style="font-size:14px;
			color: #73777a;
			padding-right:5px;" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备17156834号-1</a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302002570" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;position:relative;top:3px;"><img src="@/assets/images/gn.png" style="float:left;width:20px;"/><p style="float:left;height:20px; font-size: 14px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44011302002570号</p></a>
    </p>
  </div>

</template>

<script>
export default {
  name: "Footer20210628",
  data(){
    return {
      links:{}
    }
    },
  methods:{
    page() {
      const _this = this
      _this.$axios.get("/LinkssAll").then(res => {
        var data = res.data.data
        _this.links = data
        // _this.$message({
        //   message: _this.links,
        //   type: 'success',
        // });

      })
    },
    getUrl(url){
        // return openwindows;
        window.open(url,'_blank')

      }
      // linkDownload (url) {
      //   window.open(url,'_blank') // 新窗口打开外链接
      // }

    },

  created() {
    this.page();
  }


}
</script>

<style scoped>
.footers{
  background:#181818;
  padding:20px;
  margin-top:25px;
}
.link_list{
  width:1200px;
  margin:0 auto;
}
.link_list a{
  text-decoration: none;
  text-align: left;
  line-height: 30px;
  font-size: 16px;
  color: #9b9ea0;
  letter-spacing: .5px;
  display: inline-block;
  margin-right: 35px;
}
.footers .tit{
  width:1200px;
  margin:0 auto;
  text-align: left;
  line-height: 30px;
  font-size: 14px;
  color: #73777a;
  letter-spacing: .5px;
}
.icps{
  font-size:14px;
  color: #73777a;
  padding-right:5px;
}

</style>