<!--<template>-->

<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "product"-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->


<template>
  <!--<div class="re_box">
    <div style="background:#fff;box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);">
      <h1>
        <router-link  to="/"> <img alt="安全，万方安全" class="logo" src="@/assets/images/logo.png"></router-link>
        <span><i></i> 登录</span>
        <img class="logicon" src="@/assets/images/log.png">
        <div class="re_desc">
          <h4>万方商城中心全新升级</h4>
          <p>等保定级备案、等保合规建设，在线随时跟进进度。</p>
        </div>
      </h1>
    </div>-->
  <div>
    <HeaderNew />
<!--  </div>-->
  <div class="details">
    <Selectshop
        :proData="transmitData"
    />

    <div class="conten_body">
      <div class="conten_left">
        <div class="list">
        <ul class="nav_list"  >
          <!--          <ul class="nav_list" >-->
          <!--          <li v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName)"><a  href="" >{{ ((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName) ? thistype.thisdesType.desTypeName.trim() : '' }}</a></li>-->
          <!--          <li v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"><a  href="" >{{ thistype.thisdesType.desTypeId }}-{{ thistype.thisdesType.desTypeName.trim() }}</a></li>-->
          <!--          <li v-for="(thistype,key) in allDescribelist" v-bind:key=key v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"><a  href="" v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))" @click="jump(key)">{{ thistype.thisdesType.desTypeName.trim() }}</a></li> //returnFilePosition;-->
<!--          <li v-for="(thistype,key) in allDescribelist" v-bind:key=key v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"><a  href="" ref="spans" v-show="((key == 0) || thistype.thisdesType.desTypeName != allDescribelist[key-1].thisdesType.desTypeName&&(allDescribelist[key-1].thisdesType.desTypeName.trim()!=''))"  :style="{color: activeStep === index ? '#1987e1' : '#000000'}" @click="jump(key);">{{ thistype.thisdesType.desTypeName.trim() }}</a></li>-->
          <li v-for="(thistype,key) in allDescribelist" v-bind:key=key ><span  href="" ref="spans"   :style="{color: activeStep === index ? '#1987e1' : '#000000'}" @click="jump(key);">{{ thistype.thisdesType.desTypeName.trim() }}</span></li>
                     <li><a class="a_active" href="">服务流程</a></li>
                     <li><a  href="">服务流程</a></li>
                     <li><a href="">服务优势</a></li>
                     <li><a href="">常见问题</a></li>
                     <li><a href="">服务流程</a></li>
                     <li><a  href="">服务流程</a></li>
                     <li><a href="">服务优势</a></li>
                     <li><a href="">常见问题</a></li>
        </ul>
        </div>
        <div class="result" @scroll="onScroll">
          <div  class="con_list" v-for="(thistype1,key) in allDescribelist" v-bind:key=key >
            <h3>
              <!--            <img class="icons" src="allDescribelist[key-1].thisdesType.desTypeName">-->
              {{ thistype1.desName }}
            </h3>
            <!--          <img class="deng_icon" src="@/assets/images/b05.png">-->
            <p class="deng_item">
              <span></span>
              {{ thistype1.desComment }}
            </p>
            <!--          <p class="deng_item">-->
            <!--            <span></span>-->
            <!--            根据信息系统应用业务重要程度及其实际安全需求，实行分级、分类、分阶段实施保护，保障信息安全和系统安全正常运行，维护国家利益、公共利益和社会稳定。 等级保护的核心是对信息系统特别是对业务应用系统安全分等级、按标准进行建设、管理和监督。国家对信息安全等级保护工作运用法律和技术规范逐级加强监管力度。突出重点，保障重要信息资源和重要信息系统的安全。-->
            <!--          </p>-->
            <!--          <p class="deng_item">-->
            <!--            <span></span>-->
            <!--            等级保护是我们国家的基本网络安全制度、基本国策，也是一套完整和完善的网络安全管理体系。遵循等级保护相关标准开始安全建设是目前企事业单位的普遍要求，也是国家关键信息基础措施保护的基本要求。-->
            <!--          </p>-->
          </div>
          <!-- 为什么要开展等保工作？ -->
<!--                  <div class="con_list">-->
<!--                    <h3>-->
<!--                      <img class="icons" src="//@/assets/images/b04.png">-->
<!--                      等级保护是我国关于信息安全的基本政策-->
<!--                    </h3>-->
<!--                    <div class="kai_items">-->
<!--                      <img class="kai_icons" src="@/assets/images/b06.png">-->
<!--                      <p>等级保护是我国关于信息安全的基本政策，国家法律法规、相关政策制度要求单位开展等级保护工作。如《网络安全等级保护管理办法》和《中华人民共和国网络安全法》；</p>-->
<!--                    </div>-->
<!--                    <div class="kai_items">-->
<!--                      <img class="kai_icons" src="@/assets/images/b07.png">-->
<!--                      <p>越来越多的行业，主管/监管单位明确要求企业开展等级保护工作，目前已经下发行业要求文件的有：金融、电力、广电、医疗、教育、物流等行业，等保为准入门槛之一；</p>-->
<!--                    </div>-->
<!--                    <div class="kai_items">-->
<!--                      <img class="kai_icons" src="@/assets/images/b08.png">-->
<!--                      <p>互联网的发展同步带来网络安全事故频发，通过等级保护工作发现企业本身信息系统存在的安全隐患和不足，进行安全整改之后，提高信息系统的信息安全防护能力，降低系统被各种攻击的风险，避免信息系统核心数据丢失，及因此而带来直接或者间接的经济损失，维持企业良好的形象。</p>-->
<!--                    </div>-->
<!--                    <p class="kai_desc">因此相关单位与企业有责任与义务开展等级保护工作，落实个人及单位的网络安全保护义务，合理规避风险。</p>-->
<!--                    <p class="kai_desc1">开展网络安全等级保护测评工作刻不容缓！</p>-->
<!--                    <div class="kai_ico">-->
<!--                      <img class="kai_icons1" src="@/assets/images/b09.png">-->
<!--                      法律依据-->
<!--                    </div>-->
<!--                    <p class="deng_item">-->
<!--                      <span></span>-->
<!--                      《中华人民共和国网络安全法》第二十一条规定国家实行网络安全等级保护制度，网络运营者不履行本法第二十一条、第二十五条规定的网络安全保护义务的，由有关主管部门责令改正，给予警告；拒不改正或者导致危害网络安全等后果的，处一万元以上十万元以下罚款，对直接负责的主管人员处五千元以上五万元以下罚款。-->
<!--                    </p>-->
<!--                    <p class="deng_item">-->
<!--                      <span></span>-->
<!--                      2018年11月1日开始执行《网络安全法》-->
<!--                    </p>-->
<!--                    <div class="kai_ico">-->
<!--                      <img class="kai_icons1" src="@/assets/images/b10.png">-->
<!--                      技术指标-->
<!--                    </div>-->
<!--                    <p class="deng_item">-->
<!--                      <span></span>-->
<!--                      2008年发布的《GB/T22239-2008 信息安全技术 信息系统安全等级保护基本要求 》简称为等保1.0。-->
<!--                    </p>-->
<!--                    <p class="deng_item">-->
<!--                      <span></span>-->
<!--                      2017年8月，公安部评估中心根据网信办和安标委的意见将等级保护在编的5个基本要求分册标准进行了合并形成《网络安全等级保护基本要求》一个标准，简称等保2.0。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                      <a class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">-->
<!--                        <img class="kai_phone" src="@/assets/images/b11.png">-->
<!--                      </a>-->
<!--                    </p>-->
<!--                  </div>-->
<!--           &lt;!&ndash;顶级保护实施流程&ndash;&gt;-->
<!--                  <div class="con_list">-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      等级保护实施流程-->
<!--                    </h3>-->
<!--                    <div  class="liu_icon">-->
<!--                      <img src="@/assets/images/b12.png">-->
<!--                    </div>-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      等保测评周期-->
<!--                    </h3>-->
<!--                    <div class="zhou_box">-->
<!--                      <img class="zh_icon" src="@/assets/images/b13.png">-->
<!--                      <span>1.定级备案约7个工作日 </span>-->
<!--                      <span>2.项目备案约7个工作日 </span>-->
<!--                      <span>3.差距测评约10个工作日</span>-->
<!--                      <span>4.等保复评约7个工作日</span>-->
<!--                      <span>5.出具报告约3个工作日 </span>-->
<!--                    </div>-->
<!--                    <p class="zh_desc">（具体时间还要看需要测评的系统数量）</p>-->
<!--                    <p class="zh_red">整改加固需要客户配合，时长要看客户整改进度 <br/>等保测评和复测工作均需要授权测评机构上门调研，并进行实地测试</p>-->
<!--                    <p style="margin-top:0" class="kai_desc">建议收到上级、监管单位通知需要做等保测评时尽快开始，等保测评需要一定周期，尽早做、更安心。</p>-->
<!--                    <p style="color:#2A2A2B" class="kai_desc1">等保二级2年测评一次；等保三级1年测评一次。</p>-->
<!--                    <p>-->
<!--                      <a style="margin-top:10px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">-->
<!--                        <img class="kai_phone" src="@/assets/images/b11.png">-->
<!--                      </a>-->
<!--                    </p>-->
<!--                  </div>-->
<!--           &lt;!&ndash;为什么选择万方？万方有什么优势？&ndash;&gt;-->
<!--                  <div class="con_list" >-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      为什么选择万方？万方有什么优势？-->
<!--                    </h3>-->
<!--                    <div class="why">-->
<!--                      <img class="why_icon" src="@/assets/images/b14.png">-->
<!--                      <ul>-->
<!--                        <li><span></span>万方安全成立至今十多年，一直专注于网络安全事业；</li>-->
<!--                        <li><span></span>万方是有资质的企业，风险评估、安全工程等资质；  </li>-->
<!--                        <li><span></span>服务全国，专业安全服务备受好评； </li>-->
<!--                        <li><span></span>量身定制专属方案，节省费用； </li>-->
<!--                        <li><span></span> 实施效率高，过程透明，拿证快； </li>-->
<!--                        <li><span></span>各个行业均有多个成功案例； </li>-->
<!--                        <li><span></span> 阿里云等保咨询金牌合作伙伴；</li>-->
<!--                        <li><span></span>保密机制； 一站式服务。 </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
<!--           &lt;!&ndash;等保测评的价格&ndash;&gt;-->
<!--                  <div class="con_list">-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      等保测评的价格-->
<!--                    </h3>-->
<!--                    <div class="pri">-->
<!--                      <img class="pri_img" src="@/assets/images/b15.jpg">-->
<!--                      <p style="color:#0079EE;" class="zh_desc">（实际价格还要看测评地区及测评的系统数量、大小）</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--           &lt;!&ndash;客户能得到哪些收益&ndash;&gt;-->
<!--                  <div class="con_list">-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      客户能得到哪些收益-->
<!--                    </h3>-->
<!--                    <div class="shouyi">-->
<!--                      <ul>-->
<!--                        <li><span>1</span>满足合规性需求；</li>-->
<!--                        <li><span>2</span>了解业务安全边界，提升了业务系统安全和网络防护能力； </li>-->
<!--                        <li><span>3</span>保护企业核心信息及客户信息； </li>-->
<!--                        <li><span>4</span> 建设完善的网络安全管理制度，长制久安；</li>-->
<!--                        <li><span>5</span> 相比未过等保的，更有竞争力。 </li>-->
<!--                      </ul>-->
<!--                      <img class="why_icon" src="@/assets/images/b16.png">-->
<!--                    </div>-->
<!--                    <p>-->
<!--                      <a style="margin-top:10px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">-->
<!--                        <img class="kai_phone" src="@/assets/images/b11.png">-->
<!--                      </a>-->
<!--                    </p>-->
<!--                  </div>-->
<!--&lt;!&ndash;           行业案例&ndash;&gt;-->
<!--                  <div class="con_list">-->
<!--                    <h3>-->
<!--                      <img class="icons" src="@/assets/images/b04.png">-->
<!--                      行业案例-->
<!--                    </h3>-->
<!--                    <ul class="case">-->
<!--                      <li>-->
<!--                        <span class="tis">1.医疗行业</span>-->
<!--                        <img class="case_img" src="@/assets/images/b17.png">-->
<!--                        <div class="case_items">-->
<!--                          <h6>丽珠医药集团股份有限公司</h6>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>需求：</span>等保三级-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>周期：</span>2个月时间完成，由于客户新开业务，整改配套设施耗时较长；-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>收益：</span>(1)满足合规性需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身医疗系统经过安全测评及整改，系统安全性有基本的了解及提高。-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <span class="tis">2.金融行业</span>-->
<!--                        <img class="case_img" src="@/assets/images/b18.png">-->
<!--                        <div class="case_items">-->
<!--                          <h6>东莞市德吉特软件科技有限公司</h6>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>需求：</span>等保三级-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>周期：</span>1.5个月完成；-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>收益：</span>(1)满足金融行业监管需求；<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身金融业务系统经过安全测评及整改，系统安全性有基本的了解， 并从根本上得以提高。-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <span class="tis">3.教育行业</span>-->
<!--                        <img class="case_img" src="@/assets/images/b19.png">-->
<!--                        <div class="case_items">-->
<!--                          <h6>深圳市前海美联科技有限公司</h6>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>需求：</span>等保三级-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>周期：</span>2个月时间完成;-->
<!--                          </div>-->
<!--                          <div class="case_items_list">-->
<!--                            <span>收益：</span>(1)满足合规性需求<br/>   <span style="width:48px;display:inline-block;"></span>(2)自身在线教育系统经过安全测评及整改，系统安全性有基本的了解及提高。-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                    <span class="more_case" >更多案例请点击</span>-->
<!--                    <p style="" class="kai_desc1">等保测评刻不容缓，找万方！</p>-->
<!--                    <p>-->
<!--                      <a style="margin-top:10px;margin-bottom:30px;" class="kai_phone_box" href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">-->
<!--                        <img class="kai_phone" src="@/assets/images/b11.png">-->
<!--                      </a>-->
<!--                    </p>-->
<!--                  </div>-->
        </div>
      </div>
      <!-- right -->
      <!--      <Hotgoods/>-->



      <div class="sidebar-rolling">
        <ul>
          <li >
            <a target="_blank" href="https://mbb.eet-china.com/home/me-1850786.html">
                <span class="iconfont">
                    
                </span>
              <strong>在线咨询</strong>
            </a>
          </li>
          <li >
            <a target="_blank" href="https://mbb.eet-china.com/home/me-1850786.html">
                <span class="iconfont">
                    
                </span>
              <strong>电话联系</strong>
            </a>
          </li>
          <li >
            <a target="_blank" href="https://mbb.eet-china.com/home/me-1850786.html">
                <span class="iconfont">
                    
                </span>
              <strong>微信联系</strong>
            </a>
          </li>
<!--          <li>-->
<!--            <img src="./面包板社区-中国电子工程师的专业互动平台_files/code-icon.png" alt="" class="silderBar-icon">-->
<!--            <span class="theText thetext-pic">-->
<!--                <img src="./面包板社区-中国电子工程师的专业互动平台_files/mianbaoban-wechat(1).jpg" alt="">-->
<!--            </span>-->
<!--          </li>-->
<!--          <li class="back-top">-->
<!--            <a href="javascript:void(0)">-->
<!--                <span class="iconfont">-->
<!--                    -->
<!--                </span>-->
<!--              <strong class="hidden-xs">返回顶部</strong>-->
<!--            </a>-->
<!--          </li>-->
          <li class="shopping-cart">
            <a href="https://estore.eet-china.com/cart/index">
                <span class="iconfont">
                    
                    <span class="cart-num">1</span>
                </span>
              <strong class="hidden-xs">购物车</strong>
            </a>
          </li>
        </ul>
      </div>






    </div>

  </div>
    <Footer />
  </div>
</template>

<script type="text/javascript">

// import Hotgoods from "@/components/hotgoods.vue"
import Selectshop from "@/components/Selectshop.vue"
// import HeaderNew from "../../components/HeaderNew";
import HeaderNew from "@/pages/comm/Header20210612"
// import Foot from "@/pages/comm/"
import Footer from "@/pages/comm/Footer20210628.vue";



export default{
  name: "product",
  components:{
    HeaderNew,
    // Hotgoods,
    Selectshop,
    Footer
  },
  data(){
    return{
      // transmitData:{
      //   title:"网络安全等级保护测评服务",
      //   content:"等级保护是我们国家的基本网络安全制度、基本国策，也是一套完整和完善的网络安全管理体系。遵循等级保护相关标准开始安全建设是目前企业单位的普遍要求，也是国家关键信息基础设施保护的基本要求",
      //   price:'0',
      //   url:require('../../assets/images/b01.jpg')
      // },
      activeStep :0,
      transmitData:{},
      allDescribelist:[],
      proData:{}
      // resultDes1:[],
      // resultDes2:[],
      // resultDes3:[],
      // resultDes4:[],
      // resultDes5:[],
      // resultDes6:[],
      // resultDes7:[],
      // resultDes8:[]

    }
  },
  mounted(){


  },
  methods:{
    page(currentPage){
      const _this = this
      // _this.$axios.get("/menus?currentPage=" + currentPage).then(res => {
      let proId="1";
      _this.$axios.get("/ProductOne?proId=" + proId).then(res => {
        var data = res.data.data
        _this.transmitData = data
        _this.proData=data
        _this.allDescribelist=data.allDescribelist;
        // var allDes =_this.allDescribelist;
        // var allDesByTypeId;
        // for(i=1;i<9;i++) {
        //   _this.allDesByTypeId=allDes.find(item => item.thisdesType.desTypeId == i);
        //   _this.allDesByTypeId.concat(_this.allDesByTypeId);
        // }

        // var i=1;
        // for(let item of response.data.result) {
        //
        // }
        //
        //
        // _this.resultDes1 = allDes.find(item => item.thisdesType.desTypeId == 1);
        // _this.resultDes2 = allDes.find(item => item.thisdesType.desTypeId == 2);
        // _this.resultDes3 = allDes.find(item => item.thisdesType.desTypeId == 3);
        // _this.resultDes4 = allDes.find(item => item.thisdesType.desTypeId == 4);
        // _this.resultDes5 = allDes.find(item => item.thisdesType.desTypeId == 5);
        // _this.resultDes6 = allDes.find(item => item.thisdesType.desTypeId == 6);
        // _this.resultDes7 = allDes.find(item => item.thisdesType.desTypeId == 7);
        // _this.resultDes8 = allDes.find(item => item.thisdesType.desTypeId == 8);
        // _this.$message({
        //   message:_this.resultDes1.desName,
        //   type: 'success',
        // });

      })
      //     function DesItem (Des,n) {
      //   // return _this.allDescribelist.find(item => item.thisdesType.desTypeId == n);
      //       return Des.find(item => item.thisdesType.desTypeId == n);
      // }

    },
    jump(index) {
      var items = document.querySelectorAll(".con_list");//scroll-item");
      for (var i = 0; i < items.length; i++) {
        if (index === i) {
          items[i].scrollIntoView({
            block: "start",//默认跳转到顶部
            behavior: "smooth"//滚动的速度
          });
        }
      }
    },
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".con_list");//".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
            e.target.scrollTop >=
            scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.activeStep = i;
          var evt=window.event;
          evt.preventDefault();
          break;
        }
      }
    }//,
    // returnFilePosition(){ // vue中锚链接问题
    //   document.querySelector("#con_list").scrollIntoView(true);
    // }

  },
  created() {
    this.page(1)
  }

};
</script>

<style type="text/css" lang="less" scoped>
.details{
  width:1200px;
  margin:0 auto;

  //**/
  .conten_body{
    margin-top:10px;
    overflow: hidden;
  }
  .conten_left{
    //width:880px;
    width:1200px;
    float:left;
    background:#fff;
    box-sizing: border-box;
  }
  .nav_list{
    height:39px;
    //height:0px;
    border-bottom: 1px solid #CFCDCD;
  }
  .nav_list li{
    float:left;
    width:120px;
    text-align:center;
  }
  .nav_list li >span{
    display: inline-block;
    height:39px;
    font-size: 15px;
    line-height: 39px;
    color: #007CC6;
  }
  //.nav_list li  .a_active{
  //  color:#007CC6;
  //  border-bottom:1px solid #007CC6;
  //}
  .con_list{
    //padding:0 35px;
    //overflow:hidden;

    width: 100%;
    height: 500px;
    margin-top:20px;
  }
  .con_list h3{
    font-size: 22px;
    font-weight: bold;
    color: #2B2B2B;
    line-height: 42px;
    //margin-top:40px;
  }
  .con_list:first-child {
    height:1000px;
    margin-top: 0;
  }
  .con_list:last-child {
    height: 500px;
  }
  //.icons{
  //  width:11px;
  //  height:28px;
  //  position:relative;
  //  top:-2px;
  //}
  //.deng_icon{
  //  width: 280px;
  //  height: 232px;
  //  float:right;
  //  margin-left:10px;
  //}
  //.deng_item {
  //  position:relative;
  //  font-size: 16px;
  //  font-weight: 300;
  //  color: #2B2B2B;
  //  line-height: 23px;
  //  margin-left:20px;
  //  margin-top:15px;
  //}
  //.deng_item span{
  //  width: 6px;
  //  height:6px;
  //  border: 2px solid #007CC6;
  //  border-radius: 50%;
  //  position:absolute;
  //  top:5px;
  //  left:-18px;
  //}
  //.kai_items{
  //  margin-top:12px;
  //  overflow:hidden;
  //  font-size: 17px;
  //  font-weight: 300;
  //  color: #2B2B2B;
  //  line-height: 23px;
  //  display: table;
  //
  //}
  //.kai_icons{
  //  width: 138px;
  //  height: 138px;
  //  float:left;
  //  margin:0 15px;
  //}
  //.kai_items p{
  //  display: table-cell;
  //  vertical-align: middle;
  //  width: 629px;
  //  height:138px;
  //}
  //.kai_desc{
  //  font-size:16px;
  //  font-weight: 400;
  //  color: #007CC6;
  //  line-height: 42px;
  //  text-align: center;
  //  margin-top:30px;
  //}
  //.kai_desc1{
  //  font-size:19px;
  //  font-weight: bold;
  //  color: #007CC6;
  //  line-height: 42px;
  //  text-align:center;
  //}
  //.kai_ico{
  //  font-size:19px;
  //  font-weight: 600;
  //  color: #000000;
  //  margin-top:15px;
  //}
  //.kai_ico img{
  //  width:45px;
  //  position:relative;
  //  top:-3px;
  //  margin-left:20px;
  //
  //}
  //.kai_phone_box{
  //  display: inline-block;
  //  margin:0 auto;
  //  position:relative;
  //  margin-top:35px;
  //  left:50%;
  //  width:250px;
  //  margin-left:-125px;
  //}
  //.liu_icon{
  //  margin-top:20px;
  //  text-align: center;
  //}
  //.liu_icon img{
  //  padding:0 25px;
  //}
  //.zhou_box{
  //  position:relative;
  //  text-align:center;
  //  width:175px;
  //  height:175px;
  //  margin:0 auto;
  //  margin-top:20px;
  //}
  //.zh_icon{
  //  width:175px;
  //  height:175px;
  //}
  //.zh_desc{
  //  font-size:14px;
  //  font-weight: 600;
  //  color: #000000;
  //  text-align:center;
  //  line-height: 42px;
  //}
  //.zhou_box>span{
  //  position:absolute;
  //  width:200px;
  //  font-size:16px;
  //  font-weight: 300;
  //  color: #2A2A2B;
  //  line-height: 42px;
  //}
  //.zhou_box>span:nth-of-type(1){
  //  top:0;
  //  right:170px;
  //}
  //.zhou_box>span:nth-of-type(2){
  //  top:65px;
  //  right:200px;
  //}
  //.zhou_box>span:nth-of-type(3){
  //  bottom:0;
  //  right:170px;
  //}
  //.zhou_box>span:nth-of-type(4){
  //  top:0;
  //  left:170px;
  //}
  //.zhou_box>span:nth-of-type(5){
  //  top:65px;
  //  left:200px;
  //}
  //.zh_red{
  //  font-size:16px;
  //  font-weight: bold;
  //  color: #C52000;
  //  line-height: 19px;
  //  text-align:center;
  //  margin-top:10px;
  //}
  //.why{
  //  margin-top:10px;
  //  overflow:hidden;
  //}
  //.why_icon{
  //  width: 265px;
  //  float:left;
  //  margin-left:20px;
  //}
  //.why ul{
  //  float:left;
  //  margin-left:50px;
  //  margin-top:15px;
  //}
  //.why ul li{
  //  font-size:15px;
  //  font-weight:500;
  //  color: #2B2B2B;
  //  line-height: 29px;
  //}
  //.why ul li span{
  //  width: 5px;
  //  height: 5px;
  //  margin-right:3px;
  //  display:inline-block;
  //  border: 2px solid #007CC6;
  //  border-radius: 50%;
  //}
  //.shouyi{
  //  margin-top:10px;
  //  overflow:hidden;
  //}
  //.shouyi img{
  //  float:left;
  //  width:258px;
  //  margin-left:65px;
  //}
  //.shouyi ul{
  //  float:left;
  //  margin-left:20px;
  //}
  //.shouyi ul li{
  //  font-size:15px;
  //  color: #2B2B2B;
  //  line-height: 42px;
  //}
  //.shouyi ul span{
  //  width: 20px;
  //  display: inline-block;
  //  height: 20px;
  //  border: 2px solid #007CC6;
  //  border-radius: 50%;
  //  font-size:15px;
  //  color: #1F79FF;
  //  text-align: center;
  //  line-height: 20px;
  //  margin-right:5px;
  //}
  //.case li{
  //  position:relative;
  //  width: 769px;
  //  height: 221px;
  //  background: #FFFFFF;
  //  border: 1px solid #268CF5;
  //  border-radius: 0px 0px 20px 0px;
  //  margin:0 auto;
  //  margin-top:30px;
  //  display: flex;
  //  // align-items:center;
  //}
  //.case li .tis{
  //  width: 140px;
  //  height: 35px;
  //  display: inline-block;
  //  background: linear-gradient(-75deg, #278BF5 0%, #03A9F7 76%);
  //  border: 1px solid #268CF5;
  //  border-radius: 20px 0px 0px 0px;
  //  text-align:center;
  //  line-height:35px;
  //  color:#fff;
  //  position:absolute;
  //  top:-19px;
  //  left:0px;
  //}
  //.case li .case_img{
  //  width:150px;
  //  height:58px;
  //  margin-top:90px;
  //}
  //.case_items{
  //  flex:1;
  //  padding:10px;
  //}
  //.case_items h6{
  //  font-size:20px;
  //  font-weight: bold;
  //  color: #268CF5;
  //  line-height: 42px;
  //  margin-top:15px;
  //}
  //.case_items_list{
  //  font-size:16px;
  //  color: #272626;
  //  line-height: 26px;
  //  // display: flex;
  //}
  //.more_case{
  //  width: 159px;
  //  height: 41px;
  //  font-size:12px;
  //  line-height: 40px;
  //  display: block;
  //  margin:10px auto;
  //  text-align:center;
  //  color:#157AE4;
  //  border: 1px solid #007CC6;
  //  cursor:pointer;
  //}
  .result {
    width: 100%;
    //height: 400px;
    overflow: scroll;
  }

  .sidebar-rolling{
    position: fixed;
    bottom: 100px;
    right: 0;
    z-index: 999;
  }
  .sidebar-rolling li{
    width: 80px;
    height: 90px;
    background: #fff;
    border: 1px solid #ededed;
    text-align: center;
    border-top: none;
    position: relative;
  }
  .sidebar-rolling li a{
    width: 100%;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar-rolling li span,.sidebar-rolling li strong{
    display: block;
  }
  .sidebar-rolling li:first-child{
    border-top: 1px solid #ededed;
  }
  .sidebar-rolling li .iconfont{
    font-size: 24px;
    color: #b2b2b2;
    position: relative;
  }


}


</style>
